<template>
  <div
    class="image-block"
    :class="[
      [`desktop-format--${desktopSize}`],
      [`mobile-format--${mobileSize}`],
      verticalMargin,
      { 'hide-on-mobile': hideMobile, 'hide-on-desktop': hideDesktop },
    ]"
  >
    <ResponsiveImage
      v-if="getMobileImage.filename || desktopImage.filename"
      :lazyload="true"
      :mobile-src="getMobileImage.filename"
      :desktop-src="getDesktopImage.filename"
      :alt="altText"
      :mobile-display-size="mobileDisplaySize"
      :desktop-display-size="desktopDisplaySize"
      :caption="caption"
    />
    <mp-link :to="$u(link)" class="link" />
    <p v-if="title" class="title">
      {{ title }}
    </p>
    <div class="cta-button-wrapper" :class="alignCtas">
      <component
        :is="button.component"
        v-for="(button, index) in ctas"
        :key="index"
        v-bind="button"
        class="cta-button"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageBlock',
  props: {
    mobileImage: {
      type: [Object, String],
      required: false,
      default: null,
    },
    desktopImage: {
      type: [Object, String],
      required: false,
      default: null,
    },
    link: {
      type: [Object, String],
      required: false,
      default: null,
    },
    caption: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    verticalMargin: {
      type: String,
      required: false,
      default: 'vm--none',
    },
    mobileSize: {
      type: String,
      required: false,
      default: 'auto',
    },
    desktopSize: {
      type: String,
      required: false,
      default: 'auto',
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100vw if not sent
     */
    mobileDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    desktopDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    ctas: {
      type: Array,
      required: false,
      default: () => [],
    },
    alignCtas: {
      type: String,
      required: false,
      default: 'left',
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getMobileImage() {
      return this.mobileImage || {}
    },
    getDesktopImage() {
      return this.desktopImage || {}
    },
    altText() {
      if (this.mobileImage.alt) return this.mobileImage.alt
      if (this.desktopImage.alt) return this.desktopImage.alt
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.image-block {
  position: relative;

  // &::v-deep {
  //   picture {
  //     height: 100%;
  //   }
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //   }
  // }

  .title {
    @include h--small;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .cta-button-wrapper {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;
    padding: spacing('small');

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }
  .cta-button {
    z-index: 2;
    border-radius: 0;
  }

  &.vm--top-bottom {
    margin: spacing('large') 0;
  }

  &.vm--top {
    margin-top: spacing('large');
  }

  &.vm--bottom {
    margin-bottom: spacing('large');
  }
}
</style>
